import React from "react";
import ReactGa from "react-ga";
import { Outlet, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import ErrorPage from "../components/ErrorPage/ErrorPage";
import FileReader from "../components/FileReader/FileReader";
import Home from "../containers/Home/Home";
import PlantInformation from "../containers/PlantInformation/PlantInformation";
import ManageDocumentGroups from "../containers/Reports/ManageReportGroups/ManageDocumentGroups";
import SupportContainer from "../containers/Support/SupportContainer";
import AddDocumentContainer from "../containers/TechnicalDocumentation/AddDocumentContainer";
import PartsAndServicesContainer from "../containers/TechnicalDocumentation/PartsAndServicesContainer";
import TechnicalDocumentationContainer from "../containers/TechnicalDocumentation/TechnicalDocumentationContainer";
import TechnicalDocumentationGroupContainer from "../containers/TechnicalDocumentation/TechnicalDocumentationGroupContainer";
import TechnicalDocumentationModuleContainer from "../containers/TechnicalDocumentation/TechnicalDocumentationModuleContainer";

import PartsAndServiceContainer from "../containers/PartsAndService/PartsAndServiceContainer";

import Faq from "../components/Faq/Faq";
import Nameplate from "../components/Nameplate/Nameplate";
import PlantSiteDetails from "../components/PlantSiteDetails/PlantSiteDetails";
import SiteContacts from "../components/SiteContacts/SiteContacts";
import DiscussionBoardContainer from "../containers/DiscussionBoard/DiscussionBoardContainer";
import IssueReportContainer from "../containers/IssueReports/IssueReportContainer";
import PortalAccessRequest from "../containers/PortalAccess/PortalAccessRequest";
import TechnicalDocumentationModuleIndex from "../containers/TechnicalDocumentation/TechnicalDocumentationModuleIndex";
import SplashPage from "../containers/Welcome/SplashPage/SplashPage";

import { PrivateRoutes } from "../components/Auth/PrivateRoutes";
import { Forbidden } from "../components/ErrorPage/Forbidden";
import { SvgViewer } from "../components/UI/SvgViewer";
import { ModuleRedirector } from "../containers/Public/ModuleRedirector";
import Search from "../containers/Search/Search";
import SearchContainer from "../containers/Search/SearchContainer";
import { msalFeatureFlag } from "../utils/Auth/msalConfig";

import { AuthorizationProvider } from "../components/Auth/AuthorizationProvider";
import QuoteRequestForm from "../components/QuoteRequestSurvey/QuoteRequestForm";
import AdministrationGroupContainer from "../containers/Administration/AdministrationGroupContainer";
import AdministrationListContainer from "../containers/Administration/AdministrationListContainer";
import AdministrationListModuleContainer from "../containers/Administration/AdministrationListModuleContainer";
import { ManageRolesContainer } from "../containers/Administration/ManageRolesContainer";
import { ManageUserContainer } from "../containers/Administration/ManageUserContainer";
import AddAndEditPlant from "../containers/Administration/ModuleSpecific/PlantManagement/AddAndEditPlant";
import PlantAdded from "../containers/Administration/ModuleSpecific/PlantManagement/PlantAdded";
import { OrgGroupList } from "../containers/Administration/OrgGroupList";
import { PlantManagementContainer } from "../containers/Administration/PlantManagementContainer";
import { UserManagementContainer } from "../containers/Administration/UserManagementContainer";
import CustomerSurvey from "../containers/CustomerSurvey/CustomerSurveyContainer";
import { PortalAccessRequestSubmitted } from "../containers/PortalAccess/PortalAccessRequestSubmitted";
import QuoteRequestContainer from "../containers/QuoteRequests/ModuleSpecificInformation/QuoteRequestsContainer";
import QuoteRequestTracking from "../containers/QuoteRequests/QuoteRequestTracking";
import QuoteRequests from "../containers/QuoteRequests/QuoteRequests";
import QuoteRequestList from "../containers/QuoteRequests/QuoteRequestsList";
import ESRContainer from "../containers/Support/ESR/ESRContainer";
import BulkTechnicalDocumentModuleContainer from "../containers/DataReporting/BulkUpload/BulkTechnicalDocumentModuleContainer";
import DataReportingContainer from "../containers/DataReporting/DataReportingContainer";
import BulkAddDocumentContainer from "../containers/DataReporting/BulkUpload/BulkAddDocumentContainer";

const TRACKING_ID = "UA-249545371-2"; //Our Tracking ID
ReactGa.initialize(TRACKING_ID);

export const Router = ({ providers }: { providers: React.ReactElement }) => {
  const routes = [
    <Route element={<AuthorizationProvider />}>
      <Route element={msalFeatureFlag ? <PrivateRoutes /> : <Outlet />}>
        <Route path={"/"} element={providers}>
          <Route path="/forbidden" element={<Forbidden />} />
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/OptOut" element={<Home isEmailOptOut={true} />} />
          <Route path={"/shared-document/:fileId"}>
            <Route index element={<FileReader />} />
            <Route path=":fileURI" element={<FileReader />} />
          </Route>
          <Route path="/static-document/:staticDocumentGlobalItemId" element={<FileReader />} />
          <Route path="/document/:globalItemId" element={<FileReader />} />
          <Route path="/plant-information" element={<PlantInformation />}>
            <Route index element={<PlantSiteDetails overview={false} />} />
            <Route path="plant-site-information" element={<PlantSiteDetails overview={false} />} />
            <Route path="equipmentAttachments/:plantEquipmentId" element={<Nameplate />} />
            <Route path="site-contacts" element={<SiteContacts />} />
          </Route>
          <Route path="/issue-reports" element={<IssueReportContainer title="Issue Reports" />}>
            <Route path=":globalId/discussion-board" element={<DiscussionBoardContainer />} />
          </Route>
          <Route path="/quote-requests" element={<QuoteRequestContainer moduleId={6} title="Quote Requests" />} />
          <Route path="/search" element={<SearchContainer />}>
            <Route path=":searchText" element={<Search />} />
          </Route>
          <Route path="/parts&service" element={<PartsAndServiceContainer />}>
            <Route path={"QuoteRequests"} element={<QuoteRequests />}>
              <Route index element={<QuoteRequestList />} />
              <Route path="add" element={<QuoteRequestForm />} />
              <Route path=":quoteRequestId" element={<QuoteRequestTracking />} />
            </Route>
            <Route path="customer-survey" element={<CustomerSurvey />} />
            <Route path="customer-survey/v/:quoteRequestId" element={<CustomerSurvey viewOnly={true} />} />
            <Route path="customer-survey/:quoteRequestId" element={<CustomerSurvey />} />
          </Route>

          <Route path="/parts&service" element={<PartsAndServicesContainer />}>
            <Route path=":moduleName" element={<TechnicalDocumentationModuleContainer />}>
              <Route path=":groupId" element={<TechnicalDocumentationGroupContainer />}>
                <Route path="add" element={<AddDocumentContainer isEdit={false} />} />
                <Route path="edit" element={<AddDocumentContainer isEdit={true} />} />
              </Route>
              <Route index element={<TechnicalDocumentationModuleIndex />} />
              <Route path="manage-groups" element={<ManageDocumentGroups />} />
            </Route>
          </Route>

          <Route path="/technical-documentation" element={<TechnicalDocumentationContainer />}>
            <Route path=":moduleName" element={<TechnicalDocumentationModuleContainer />}>
              <Route path=":groupId" element={<TechnicalDocumentationGroupContainer />}>
                <Route path="add" element={<AddDocumentContainer isEdit={false} />} />
                <Route path="edit" element={<AddDocumentContainer isEdit={true} />} />
              </Route>
              <Route index element={<TechnicalDocumentationModuleIndex />} />
              <Route path="manage-groups" element={<ManageDocumentGroups />} />
            </Route>
          </Route>
          <Route path="/support" element={<SupportContainer />}>
            <Route path="faq/:supportModuleName" element={<Faq />} />
            <Route path="esr/:supportModuleName" element={<ESRContainer />} />
          </Route>
          <Route path="/administration/organization-management" element={<OrgGroupList />} />
          {/* <Route path="/administration/plant-management" element={<OrgGroupList />} /> */}
          {/* <Route path="/administration/role-configuration" element={<OrgGroupList />} /> */}
          <Route path="/administration/user-management" element={<UserManagementContainer section="default" />} />
          <Route path="/administration/user-management/:userId" element={<ManageUserContainer />} />
          <Route path="/administration/user-management/internal" element={<UserManagementContainer section="internal" />} />
          <Route path="/administration/user-management/external" element={<UserManagementContainer section="external" />} />
          <Route path="/administration/role-configuration/manage-roles" element={<ManageRolesContainer />} />

          <Route path="/administration/data-reporting" element={<DataReportingContainer />}>
            <Route path=":moduleName" element={<BulkTechnicalDocumentModuleContainer />}>
              <Route path="add" element={<BulkAddDocumentContainer />} />
            </Route>
          </Route>

          <Route path="/administration/Plant-Management/plant/:plantId" element={<PlantManagementContainer />}>
            <Route path="edit" element={<AddAndEditPlant edit={true} />} />
          </Route>

          <Route path="/administration" element={<AdministrationListContainer />}>
            <Route path=":moduleName" element={<AdministrationListModuleContainer />}>
              <Route path=":groupId" element={<AdministrationGroupContainer />} />
              <Route path="add" element={<AddAndEditPlant edit={false} />} />
              <Route path="saved" element={<PlantAdded />} />
            </Route>
          </Route>
          <Route path="/module/:moduleId/*" element={<ModuleRedirector />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Route>
      <Route path="/portal-access" element={<PortalAccessRequest />} />,
      <Route path="/portal-access/submitted" element={<PortalAccessRequestSubmitted />} />,
      <Route path="/splash" element={<SplashPage />} />,
    </Route>
  ];
  if (process.env.NODE_ENV === "development") {
    routes.push(<Route path="/svgs" element={<SvgViewer />} />);
  }
  return createBrowserRouter(createRoutesFromElements(routes));
};
