import { Outlet, useOutletContext } from "react-router-dom";
import { useBaseOrgIdHeader } from "../../../customHooks/useBaseOrgIdHeader";
import { useContext, useEffect, useState } from "react";
import { Store } from "../../../Store";
import { BulkCreateResponse, DataWithPermissions, DocumentGroup, NotificationType, OMManualSectionGetAllDTO, Permissions } from "../../../utils/interface";
import { ModuleIDs } from "../../../utils/moduleIDs";
import { useOnError } from "../../../customHooks/useOnError";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import { useGetAll_OMManualSectionData } from "../../CPItemConfiguration/OMManualSectionHttpServices";
import { useApiProductType } from "../../PlantInformation/TypesHttpServices";
import { BulkDocumentModuleInterface } from "../DataReportingContainer";

export interface BulkTechnicalDocumentModuleContainerInterface extends BulkDocumentModuleInterface {
  documentModules: BulkDocumentModuleInterface[];
  documentModule: BulkDocumentModuleInterface;
  documentGroups: DocumentGroup[];
  productTypes: SelectOptionInterface[];
  omSections: SelectOptionInterface[];
  setDocumentGroups: React.Dispatch<React.SetStateAction<DocumentGroup[]>>;
  documentAdded: BulkCreateResponse | undefined;
  setDocumentAdded: React.Dispatch<React.SetStateAction<BulkCreateResponse | undefined>>;
  notificationTypes: NotificationType[];
  notifications: Notification[];
  refetch: any;
  groupPermissions: Permissions;
  reportPermissions: Permissions;
  setReportPermissions: React.Dispatch<React.SetStateAction<Permissions>>;
}

export interface ProductTypeInterface {
  id: number;
  name: string;
}

export interface SelectOptionInterface {
  id: number | string;
  name: string;
}

const BulkTechnicalDocumentModuleContainer = () => {
  const hasBaseOrgForHeader = useBaseOrgIdHeader();
  const {
    productType: { data: productType },
    setProductType
  } = useContext(Store);
  const [omManualSections, setOmManualSections] = useState<SelectOptionInterface[]>([]);
  const [productTypes, setProductTypes] = useState<SelectOptionInterface[]>([]);
  const outletContext = useOutletContext<BulkTechnicalDocumentModuleContainerInterface>();
  const { documentModule } = outletContext;
  const setErrorData = useOnError();

  useGetAll_OMManualSectionData(
    (data: OMManualSectionGetAllDTO) => {
      if (documentModule?.id === ModuleIDs.OMManuals) {
        setOmManualSections(data.data);
      }
    },
    () => {}
  );

  useApiProductType(setProductType, setErrorData);

  useEffect(() => {
    if (productType) {
      setProductTypes(productType);
    }
  }, [productType]);

  const [documentGroups, setDocumentGroups] = useState<DocumentGroup[]>([]);
  const [documentAdded, setDocumentAdded] = useState<BulkCreateResponse | undefined>(undefined);

  const [groupPermissions, setGroupPermissions] = useState<Permissions>({
    baseOrg: null,
    canAdd: false,
    canAdmin: false,
    canDelete: false,
    canEdit: false,
    canView: false,
    orgGroup: null,
    role: ""
  });
  const [reportPermissions, setReportPermissions] = useState<Permissions>({
    baseOrg: null,
    canAdd: false,
    canAdmin: false,
    canDelete: false,
    canEdit: false,
    canView: false,
    orgGroup: null,
    role: ""
  });

  const sortByDisplayOrderAsc = ({ displayOrder: a }: DocumentGroup, { displayOrder: b }: DocumentGroup): number => {
    if (a > b) return 1;
    if (b > a) return -1;
    return 0;
  };

  const { refetch, isFetching } = useQuery(`${documentModule?.name}.documentGroups.get`, documentModule ? documentModule.documentGroupApi.getAll : () => new Promise((resolve) => resolve([])), {
    onSuccess: (response: DataWithPermissions<DocumentGroup[]>) => {
      const { data, permissions } = response;
      setGroupPermissions(permissions);
      setDocumentGroups(data?.sort(sortByDisplayOrderAsc) || []);
    },
    onError: (error: AxiosError<string>) => {
      setErrorData(error);
    },
    enabled: hasBaseOrgForHeader
  });

  return (
    <>
      {documentModule && (
        <>
          <Outlet context={{ documentModule: documentModule, omSections: omManualSections, productTypes, refetch, documentGroups: isFetching ? [] : documentGroups, setDocumentGroups, documentAdded, setDocumentAdded, groupPermissions, reportPermissions, setReportPermissions }} />
        </>
      )}
    </>
  );
};

export default BulkTechnicalDocumentModuleContainer;
