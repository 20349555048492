import { Form, Formik, FormikHelpers } from "formik";
import { FunctionComponent, useEffect, useState } from "react";
import * as Yup from "yup";
import ErrorCard from "../../../components/ErrorCard/ErrorCard";
import DragDropFieldFormik from "../../../components/UI/Form/DragDropField/DragDropFieldFormik";
import DropDownSelect from "../../../components/UI/Form/DropDownSelect/DropDownSelect";
import MultiSelectDropDown from "../../../components/UI/Form/DropDownSelect/MultiSelectDropDown";
import TextInputField from "../../../components/UI/Form/TextInputField/TextInputField";
import { useStore } from "../../../useStore";
import ImageImports from "../../../utils/ImageImports";
import classes from "./BulkAddDocumentForm.module.css";
import DragDropFieldInfo from "../../../components/UI/Form/DragDropField/DragDropFieldInfo";
import { BulkAddDocumentRequiredProps } from "../DataReportingContainer";
import { ModuleIDs } from "../../../utils/moduleIDs";
import { SelectOptionInterface } from "./BulkTechnicalDocumentModuleContainer";

const { trash } = ImageImports;

const IImplementationStatus: SelectOptionInterface[] = [
  {
    id: "Not Performed",
    name: "Not Performed"
  },
  {
    id: "Partially Performed",
    name: "Partially Performed"
  },
  {
    id: "Completed",
    name: "Completed"
  }
];

export interface ITechDocFormField {
  displayName: string;
  itemTypeId: number | null;
  productTypeId: number | null;
  baseOrgIds: number[];
  file: string;
}

export interface IPowerReportFormField extends ITechDocFormField {}
export interface IOmFormField extends ITechDocFormField {
  sectionId: number | null;
}
export interface ITechBulletinFormField extends ITechDocFormField {
  scope: string;
  summaryGuideline: string;
  implementationStatus: string;
}

export type TechDocFormFieldType = IPowerReportFormField | IOmFormField | ITechBulletinFormField;

const BulkAddDocumentForm: FunctionComponent<BulkAddDocumentRequiredProps> = ({ documentGroups, onSave, documentModule, productTypes, omSections, isLoading }) => {
  const {
    showError,
    header: {
      data: {
        user: { plants }
      }
    },
    setShowError
  } = useStore();
  const [plantOptions, setPlantOptions] = useState<{ id: number; name: string }[]>([]);
  const [attachment, setAttachment] = useState<File>();
  const [selectedPlants, setSelectedPlants] = useState<number[]>([]);
  const [selectionText, setSelectionText] = useState<string>("");
  const [formReset, setFormReset] = useState<boolean>(false);
  const [displayName, setDisplayName] = useState<string>("");

  useEffect(() => {
    const plantList = [...new Set(plants?.map((i) => i.baseOrgId))];
    setPlantOptions([{ id: 0, name: "All Plants" }, ...plantList.map((p) => ({ id: p, name: plants?.find((i) => i.baseOrgId === p)?.name as string }))]);
  }, [plants]);

  const handleOnSubmit = (values: any, formikHelpers: FormikHelpers<any>) => {
    setShowError?.({ title: "", ErrorType: null, isError: false });
    const formData = new FormData();
    for (let value in values) {
      if (value === "baseOrgIds") {
        const aE = values[value];
        aE.forEach((e: number, i: number) => {
          formData.append(`baseOrgIds[${i}]`, `${e}`);
        });
      } else {
        formData.append(value, values[value]);
      }
    }

    onSave(formData);
  };

  useEffect(() => {
    setDisplayName(attachment?.name || "");
    setShowError?.({ title: "", ErrorType: null, isError: false });
  }, [attachment, setShowError]);

  useEffect(() => {
    // some values get set after formik reinitializes
    if (formReset) {
      setSelectionText("");
      setFormReset(false);
      setAttachment(undefined);
    }
  }, [formReset]);

  let initialValues: TechDocFormFieldType;
  let validationSchema;
  let validationObject = {
    itemTypeId: Yup.number().required("This field is required"),
    productTypeId: Yup.number().required("This field is required"),
    baseOrgIds: Yup.array().of(Yup.number()).min(1, "At least one Plant Site must be selected").required("At least one Plant Site must be selected"),
    file: Yup.string().required("This field is required")
  };

  initialValues = {
    displayName: "",
    productTypeId: null,
    itemTypeId: null,
    baseOrgIds: [],
    file: ""
  };

  if (documentModule?.id === ModuleIDs.OMManuals) {
    (initialValues as IOmFormField).sectionId = null;
    (validationObject as any).sectionId = Yup.number().required("This field is required");
  }

  if (documentModule?.id === ModuleIDs.TechnicalBulletins) {
    (initialValues as ITechBulletinFormField).scope = "";
    (initialValues as ITechBulletinFormField).summaryGuideline = "";
    (initialValues as ITechBulletinFormField).implementationStatus = "";
    (validationObject as any).scope = Yup.string().required("This field is required");
    (validationObject as any).summaryGuideline = Yup.string().required("This field is required");
    (validationObject as any).implementationStatus = Yup.string().required("This field is required");
  }

  validationSchema = Yup.object({
    ...validationObject
  });

  const getPlantSiteLabel = (values: number[]) => {
    if (!!(values.length > 1)) {
      setSelectionText("Multiple plants selected");
    }
    // if values includes 'All Equipment'
    if (values.includes(0)) setSelectionText(plantOptions[0].name);
    // if only 1 option selected
    if (values.length === 1) setSelectionText(plantOptions.filter((el) => el.id === values[0])[0]?.name);
  };
  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
      {({ values, setFieldValue, isSubmitting, dirty, isValid, errors }) => {
        const handleEquipmentSelection = (values: number[], toggled: number) => {
          let tmpValues = [...values];
          let tmpSiteEquipment = plantOptions || [];
          if (toggled === 0) {
            if (values.includes(0)) {
              tmpValues = tmpSiteEquipment.map((d) => d.id);
            } else {
              tmpValues = [];
            }
          }

          tmpValues = tmpValues.filter((v, i, a) => a.indexOf(v) === i);

          // if everything is selected, check select all
          if (tmpValues.length > 0 && ((!tmpValues.includes(0) && tmpValues.length === tmpSiteEquipment.length - 1) || (tmpValues.includes(0) && tmpValues.length === tmpSiteEquipment.length))) {
            tmpValues.push(0);
          } else {
            tmpValues = tmpValues.filter((val) => val !== 0);
          }

          tmpValues = tmpValues.filter((v, i, a) => a.indexOf(v) === i);

          setSelectedPlants(tmpValues);
          setFieldValue(
            "baseOrgIds",
            tmpValues.filter((d) => d > 0)
          );
          getPlantSiteLabel(tmpValues);
        };

        return (
          <>
            {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}

            <Form>
              <div>
                <div>
                  <TextInputField name="displayName" disabled={true} details={"Document name will auto-populate to match the attachment’s filename."} labelName="Document Name*" placeholder="Document Name" className="" />
                </div>
                {plantOptions && (
                  <div>
                    <div className={`${classes.form_field_row} ${classes.add_document_field}`}>
                      <span className={classes.input_label}>Plant Sites*</span>
                      <div className={classes.form_group}>
                        <MultiSelectDropDown
                          value={selectedPlants}
                          className={`${classes.select_container} ${errors.baseOrgIds ? `${classes.input} ${classes.errorField}` : ""} ${selectedPlants.length > 0 ? classes.selected : classes.placeholder}`}
                          selectClass={`${classes.flex_row} ${classes.select}`}
                          optionsClass={`${classes.flex_column} ${classes.select_options}`}
                          options={plantOptions}
                          noSelectionText="Select Plant Site(s)"
                          hasSelectionText={selectionText}
                          onSelection={handleEquipmentSelection}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  <DropDownSelect name="itemTypeId" options={documentGroups} labelName="Document Group*" placeholder={"Select Document Group"} defaultValue={""} />
                </div>

                <div>
                  <DropDownSelect
                    name="productTypeId"
                    options={productTypes}
                    labelName="Product Type*"
                    placeholder={"Select Product Type"}
                    defaultValue={""}
                    tooltip={{
                      text: "Document will be added to all Units/Equipment associated with the selected Product Type for each Plant Site(s) selected above"
                    }}
                  />
                </div>

                {documentModule?.id === ModuleIDs.OMManuals && (
                  <div>
                    <DropDownSelect name="sectionId" options={omSections} labelName="Section*" placeholder={"Select OM Manual Section"} defaultValue={""} />
                  </div>
                )}

                {documentModule?.id === ModuleIDs.TechnicalBulletins && (
                  <>
                    <div>
                      <TextInputField
                        name="scope"
                        labelName="Scope* "
                        className="modalInput"
                        placeholder="Describe scope"
                        tooltip={{
                          text: "Add a summary explaining the content of the document here"
                        }}
                      />
                    </div>
                    <div>
                      <TextInputField
                        name="summaryGuideline"
                        labelName="Summary*"
                        className="modalInput"
                        placeholder="Add a summary"
                        tooltip={{
                          text: "This is a Summary of the Recommendation provided in the Technical Bulletin"
                        }}
                      />
                    </div>
                    <div>
                      <DropDownSelect name="implementationStatus" options={IImplementationStatus} placeholder={"Select implementation status"} defaultValue={""} labelName="Implementation Status*" />
                    </div>
                  </>
                )}

                <div>
                  <>
                    <div className={`${classes.form_field_row} ${classes.add_document_field}`}>
                      <span className={classes.input_label}>Attachment*</span>
                      {attachment && (
                        <div className={classes.form_group}>
                          <input type="text" disabled className={`${classes.input} pr-8`} value={displayName} />
                          <img
                            className={classes.deleteFile}
                            onClick={() => {
                              setFieldValue("displayName", "");
                              setFieldValue("file", "");
                              setAttachment(undefined);
                            }}
                            src={trash}
                            alt="Remove Attachment"
                          />
                        </div>
                      )}
                    </div>
                    <DragDropFieldFormik name="file" onChange={setAttachment} displayName={"displayName"} attachment={attachment} isAllowOnlyPdfImage={documentModule?.id === ModuleIDs.TechnicalBulletins} />
                    <DragDropFieldInfo fileTypes={documentModule?.id === ModuleIDs.TechnicalBulletins ? ".PDF, .JPG, .PNG" : ".PDF, .DOC, .XLSX, .JPG, .PNG"} containerClassName="mb-5" />
                  </>
                </div>
              </div>
              <div className="flex flex-row justify-between items-center">
                <div className="flex-grow-1 flex">
                  <>
                    <button type="submit" className="primaryBtn darkBlue" disabled={!dirty || (dirty && !isValid) || !attachment}>
                      Add Document
                    </button>
                  </>
                </div>
                <span>*Required fields</span>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default BulkAddDocumentForm;
