import { FunctionComponent } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import classes from "./BulkAddDocumentContainer.module.css";
import { BulkTechnicalDocumentModuleContainerInterface } from "./BulkTechnicalDocumentModuleContainer";
import { useBulkCreateMitsubishiPowerReport, useBulkCreateOMManual, useBulkCreateTechBulletin } from "../../Reports/ReportsHttpServices";
import { ModuleIDs } from "../../../utils/moduleIDs";
import { useStore } from "../../../useStore";
import { CloseAllModal } from "../../../utils/CloseAllModal";
import Loader from "../../../components/Loader/Loader";
import ModalGlobal from "../../../components/UI/Modal/ModalGlobal";
import Button from "../../../components/Button/Button";
import { useOnError } from "../../../customHooks/useOnError";
import { BulkCreateResponse } from "../../../utils/interface";

const getService = (moduleId: ModuleIDs) => {
  switch (moduleId) {
    case ModuleIDs.MitsubishiPowerReports:
      return useBulkCreateMitsubishiPowerReport;
    case ModuleIDs.OMManuals:
      return useBulkCreateOMManual;
    case ModuleIDs.TechnicalBulletins:
      return useBulkCreateTechBulletin;
    default:
      return useBulkCreateMitsubishiPowerReport;
  }
};

const BulkAddDocumentContainer: FunctionComponent = () => {
  const { documentModule, documentGroups, omSections, productTypes, setDocumentAdded, documentAdded } = useOutletContext<BulkTechnicalDocumentModuleContainerInterface>();

  const navigate = useNavigate();
  const { addDocumentModalBodyContent: DocumentModalBodyContent } = documentModule;
  const {
    setIsModal,
    header: {
      data: {
        user: { plants }
      }
    },
    setShowError
  } = useStore();
  const setErrorData = useOnError();
  // const [onShareMode, setOnShareMode] = useState<boolean | undefined>(undefined);

  const {
    mutate: AddDocument,
    isSuccess,
    isLoading: isAdding,
    data: responseData
  } = getService(documentModule.id)({
    onError: (error: unknown) => {
      setErrorData(error);
    },
    onSuccess: (response: BulkCreateResponse) => {
      setShowError?.({ title: "", ErrorType: null, isError: false });
      setDocumentAdded(response);
    }
  });

  //const { mutate: shareWithUG, isLoading: isSharing, isSuccess: isShared } = useShareWithUG_TBReport();

  const handleCloseModal = () => {
    setIsModal?.({ ...CloseAllModal });
    navigate("../..");
  };

  // **** TODO **** //
  // Need to add array of global item ids added to the response so we can share report with UG

  // const handleShareWithUG = () => {
  //   console.log('Share with UG still to be configured');
  //   setOnShareMode(true);
  //   !!responseData?.globalItemId && shareWithUG(responseData?.globalItemId, {
  //     onError: (error: unknown) => {
  //       setErrorData(error);
  //     },
  //   });
  // };

  const getPlantNames = () => {
    if (documentAdded) {
      const plantNameList = plants.filter((plant) => documentAdded.plantIds.includes(plant.plantId)).map((d) => d.name);
      return plantNameList.join(", ");
    }
    return "";
  };

  return (
    <>
      {isAdding && (
        <div className="fixed top-0 left-0 w-full h-screen z-40 !overflow-hidden opacity-50 bg-gray-100 flex flex-col items-center justify-center">
          <Loader />
        </div>
      )}
      <ModalGlobal
        modaltitle={isSuccess ? `Document Successfully Added to Plant Site(s)` : `Bulk Add ${documentModule.addEditHeaderName} to Plant Site(s)`}
        isModalHeader={true}
        isTitleCenter={!!(isSuccess && responseData)}
        isContentCentered={!!(isSuccess && responseData)}
        modalClose={handleCloseModal}
        //modalSize={!!(isSuccess && (responseData)) ? "special" : "md"}
        modalSize={!!isSuccess ? "special" : "md"}
      >
        {!isSuccess && documentModule.addModalHeaderContent.length > 0 && (
          <div>
            {documentModule.addModalHeaderContent.map((e, i) => {
              return (
                <p key={i} className={classes.modal_body_text}>
                  {e}
                </p>
              );
            })}
          </div>
        )}
        {isSuccess && responseData ? (
          <div>
            <p>
              {responseData.displayName} was successfully added to {responseData.moduleName} Document Group "{responseData.groupName}" for {responseData.plantCount} Plant Site(s)
            </p>
            <p>{getPlantNames()}</p>
            <div className={`${classes.flex_row} ${classes.add_document_confirmation_buttons}`}>
              {/* 
              // **** TODO **** // 
              // Add functionality to copy to UG Portal
              */}
              {/* {documentModule.id === ModuleIDs.TechnicalBulletins && (
                <Button className={`darkBlue`} text="Copy to Users’ Group Portal" onClick={handleShareWithUG} />
              )} */}

              <Button className={`whiteBtn`} text="Done" onClick={handleCloseModal} />
            </div>
          </div>
        ) : (
          <DocumentModalBodyContent documentModule={documentModule} isLoading={{ isAdding }} productTypes={productTypes} omSections={omSections} documentGroups={documentGroups} onSave={AddDocument} />
        )}
      </ModalGlobal>

      {/* // **** TODO **** // */}
      {/* {onShareMode && (
        <CopyDocumentModal isSharing={isSharing} isShared={isShared} currentReport={reportData} handleCloseModal={handleCloseModal} />
      )} */}
    </>
  );
};

export default BulkAddDocumentContainer;
